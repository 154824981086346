import PropTypes from "prop-types";
import React from "react";
import {  useStaticQuery,graphql,Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import FooterNishiogi from "./footerNishiogi"
import MenuLp from "./menuLp"
import Header from "./header"

import Mail from "../static/svg/icon-mail.inline.svg"
import ChevronDoubleRight from "../static/svg/icon-chevron-double-right.inline.svg";
import Home from "../static/svg/icon-home.inline.svg";
import BackArrow from "../static/svg/icon-back-arrow.inline.svg"



function LayoutLp ({ children, pathTop, isSubPage, isThanks }){

  const data = useStaticQuery(graphql`
  query layoutLpQuery {
    site {
      siteMetadata {
        tel
      }
    }
  }
  
  `);

  const toForm =  pathTop.substr(0, pathTop.length - 1).concat('#form') ;

  return (
    <div className="w-full flex flex-col min-h-screen  text-gray-900 tracking-wide leading-8 font-body select-none">
    <MenuLp pathTop={pathTop} isSubPage={isSubPage} /> 
      <main
        className="container flex-1  w-full max-w-screen-2xl mx-auto md:px-8 ">
 <Header pathTop={pathTop} tel={data.site.siteMetadata.tel} isNishiogiOnly />
        <div className="max-w-3xl mx-auto">



        {isSubPage ?
        <div className=" mt-8 mx-8 ">
          {children}
                      <Link to={pathTop} >
                      <button type='button'
                      className="border border-gray-500 hover-hover:hover:bg-gray-200  text-center py-2 px-4 my-8 rounded font-semibold shadow-xl shadow-gray-600/50 rounded-full">
                      <BackArrow className="h-6 w-6 align-text-bottom inline-block  text-lx " />
                      <Home className="h-6 w-6 inline-block  align-text-bottom" />
                      トップへ戻る
                    </button>
                  </Link>
                  </div>

                  
                      : <>{children}</>}
                              </div>
              

        <FooterNishiogi />

      </main>
      <div className="sticky bottom-5   z-30  text-right" >
        { isSubPage ? null
        :
        <div className="">

          <AnchorLink to={toForm} className="" stripHash>
            <button type="button" aria-label="Form" id="btn_to_form" 
              className=" text-2xl mr-4  leading-3 bg-pink-200 rounded-full px-4 py-3 align-middle border-pink-300 border-2 border-solid  hover-hover:hover:bg-pink-400 shadow-pink-700/50 shadow-xl ">
              <Mail className="w-7 h-7 inline-block" />
              <span className="text-base text-bold">&nbsp;ご相談・ご予約はこちら&nbsp;&nbsp;</span>
              <ChevronDoubleRight className="h-5 w-5 inline-block ml-2 " />
            </button>
        </AnchorLink> 

        </div>
}
      </div>

  </div>

  );
}

LayoutLp.defaultProps = {
  pathTop: `/lp-holistic/`,
  isSubPage: false,
  isThanks: false,
};

LayoutLp.propTypes = {
  children: PropTypes.node.isRequired,
  pathTop: PropTypes.string,
  isSubPage: PropTypes.bool,
  isThanks: PropTypes.bool,
};

export default LayoutLp;