import { useStaticQuery,graphql,Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Call from "../static/svg/icon-call.inline.svg";
import Home from "../static/svg/icon-home.inline.svg"
import Map from "../static/svg/icon-map.inline.svg"

function MenuLp( props ) {

  const data = useStaticQuery(graphql`
  query menuLpQuery {
    site {
      siteMetadata {
        tel
      }
    }
  }
  
  `);

  const  tel   = (data.site.siteMetadata.tel).replace(/\s+/g, "");
  const { pathTop }  = props;

  const ToAccess =  pathTop.substr(0, pathTop.length - 1).concat('#map') ;


  const { isSubPage } = props;

  return (
  <>

    <div className="sticky top-0 bg-yellow-100 top-0 z-30 border-b-2 border-yellow-600 md:border-none" >
    <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-0.5 py-1.5 mx-auto md:px-0 md:py-0">

          <Link to={pathTop} className="md:hidden">
            <button type="button" aria-label="Top"
              className="ml-1 pt-px   md:hidden text-2xl mr-4 w-12 h-12 leading-3">
              <Home className="w-7 h-7 inline-block" />
              <span className="text-xs"><br />トップ</span>
            </button>
          </Link>
          
         {isSubPage ? null :
          <AnchorLink to={ToAccess} className="md:hidden">
            <button type="button" aria-label="Access"
              id="btn_to_access" className="pt-px md:hidden text-2xl mr-1 w-12 h-12 leading-3">
              <Map className="w-7 h-7 inline-block" />
              <span className="text-xs"><br />アクセス</span>
            </button>
          </AnchorLink> 
          }


          <a href={`tel:${tel}`}>
            <button type="button" aria-label="Tel"
              className="py-px px-4 
              border-orange-200 border-2 border-solid bg-orange-100 hover-hover:hover:bg-orange-300
              shadow-orange-700/50  shadow-xl 
                text-2xl
              md:hidden mr-2 h-12 leading-3 rounded-full ">
              <Call className="w-5 h-5 inline-block" />
              <span className="text-sm font-mono align-middle">&nbsp;{data.site.siteMetadata.tel}<br /></span><span className="text-sm">平日 10:00~18:00</span>
            </button></a>
   
    </div>
    </div>


          </>



  );
}

MenuLp.defaultProps = {
  pathTop: `/lp-holistic/`,
  isSubPage: false,
};

MenuLp.propTypes = {
  pathTop: PropTypes.string,
  isSubPage: PropTypes.bool,
};

export default MenuLp;