import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

import PenLogo from "../static/svg/pen-logo.inline.svg";

const SvgBg = styled.div`

  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20xmlns%3Asvgjs%3D%22http%3A%2F%2Fsvgjs.dev%2Fsvgjs%22%20viewBox%3D%220%200%20700%20700%22%20width%3D%22700%22%20height%3D%22700%22%3E%3Cdefs%3E%3Cfilter%20id%3D%22nnnoise-filter%22%20x%3D%22-20%25%22%20y%3D%22-20%25%22%20width%3D%22140%25%22%20height%3D%22140%25%22%20filterUnits%3D%22objectBoundingBox%22%20primitiveUnits%3D%22userSpaceOnUse%22%20color-interpolation-filters%3D%22linearRGB%22%3E%20%3CfeTurbulence%20type%3D%22fractalNoise%22%20baseFrequency%3D%220.102%22%20numOctaves%3D%224%22%20seed%3D%2215%22%20stitchTiles%3D%22stitch%22%20x%3D%220%25%22%20y%3D%220%25%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20result%3D%22turbulence%22%3E%3C%2FfeTurbulence%3E%20%3CfeSpecularLighting%20surfaceScale%3D%2215%22%20specularConstant%3D%220.75%22%20specularExponent%3D%2220%22%20lighting-color%3D%22hsl(22.5%2C%20100%25%2C%2033%25)%22%20x%3D%220%25%22%20y%3D%220%25%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20in%3D%22turbulence%22%20result%3D%22specularLighting%22%3E%20%3CfeDistantLight%20azimuth%3D%223%22%20elevation%3D%22100%22%3E%3C%2FfeDistantLight%3E%20%3C%2FfeSpecularLighting%3E%20%3C%2Ffilter%3E%3C%2Fdefs%3E%3Crect%20width%3D%22700%22%20height%3D%22700%22%20fill%3D%22hsl(51%2C%2061%25%2C%2087%25)%22%3E%3C%2Frect%3E%3Crect%20width%3D%22700%22%20height%3D%22700%22%20fill%3D%22hsl(22.5%2C%20100%25%2C%2033%25)%22%20filter%3D%22url(%23nnnoise-filter)%22%3E%3C%2Frect%3E%3C%2Fsvg%3E");
  
`;


function LpHolisticHeroImg() {
    return (
<>

    <div className="max-w-3xl text-center m-auto">
    <SvgBg className="lp-hero flex-col m-auto  relative">
    <div className="w-full">
    <StaticImage
          src="../images/lp-top.png"
          alt="肩がバリバリ、腰が重い、疲れが取れない、瀬永が痛いとため息をつく女性"
          placeholder="tracedSVG"
          layout="constrained"
          loading="eager"
        />
    </div>
    </SvgBg>
   
    
    <div className="arrow_box_t py-4 sm:p-3 text-slate-900 ">
    <p className="text-center font-bold text-2xl sm:text-3xl md:text-rxl"><PenLogo className="inline-block mr-1.5 -mt-1.5 width='10px' height='10px'" /><span className="marker">もう我慢しなくていいんです</span></p>
        <ul className="mx-8 py-3 text-base list-outside list-disc mb-0 text-left sm:text-lg md:text-xl font-semibold"><li className="my-2">朝から股関節に違和感があり、一日の始まりが憂鬱…</li>
<li className="my-2">長時間立っているとつらくなってくる。</li>
<li className="my-2">仕事の日は夕方には動くのが辛くなる。</li>
<li className="my-2">痛みを我慢して集中力を欠き、仕事のパフォーマンスが落ちてしまう。</li>
<li className="my-2">自己流のケアやマッサージでは一時的に楽になるが、結局また元に戻ってしまう。</li>
<li className="my-2">病院に行っても「とりあえず安静にして」と言われるだけ。</li>
<li className="my-2">趣味や友人との時間を楽しむ余裕がなくなってきた。</li></ul>
<p className="text-center  text-xl sm:text-2xl md:text-3xl">
        「うんうん、そうだよね」<span  className="sm:hidden"><br /></span>と頷いたあなたに、<span  className="sm:hidden"><br /></span>解決の鍵&#x1f511;をお伝えします。</p>

    </div>




            </div>


    </>
    )
};

export default LpHolisticHeroImg;